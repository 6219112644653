import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromVisualizer from './visualizer.reducer';
import { state } from '@angular/animations';

export const selectVisualizerState = createFeatureSelector<fromVisualizer.State>(
  fromVisualizer.visualizerFeatureKey
);

export const selectHydratedSubStores = createSelector(selectVisualizerState, state => state.hydratedStores);
export const selectOntologySelectorDisplay = createSelector(selectVisualizerState, state => state.ontologiesSelector);
export const selectEntityTypesSelectorDisplay = createSelector(selectVisualizerState, state => state.entityTypesSelector);
export const selectRelationTypesSelectorDisplay = createSelector(selectVisualizerState, state => state.relationTypesSelector);