import { Component, OnInit } from '@angular/core';
import { Observable, combineLatest } from 'rxjs';
import { DisplayState } from '../../store/visualizer/visualizer.reducer';
import { Store } from '@ngrx/store';
import { selectEntityTypesSelectorDisplay, selectRelationTypesSelectorDisplay } from '../../store/visualizer/visualizer.selectors';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-selectors-top',
  templateUrl: './selectors-top.component.html',
  styleUrls: ['./selectors-top.component.scss']
})
export class SelectorsTopComponent implements OnInit {

  entityTypesSelectorDisplay$: Observable<DisplayState>;
  relationTypesSelectorDisplay$: Observable<DisplayState>;
  hideTopNav$: Observable<boolean>;
  DisplayState = DisplayState;


  constructor(private store: Store) { }

  ngOnInit(): void {
    this.entityTypesSelectorDisplay$ = this.store.select(selectEntityTypesSelectorDisplay);
    this.relationTypesSelectorDisplay$ = this.store.select(selectRelationTypesSelectorDisplay);
    this.hideTopNav$ = combineLatest([
      this.entityTypesSelectorDisplay$,
      this.relationTypesSelectorDisplay$
    ]).pipe(
      map(([entityDisplay, relationDisplay]) => {
        return entityDisplay === DisplayState.HIDDEN && entityDisplay === DisplayState.HIDDEN;
      })
    )
  }

}
