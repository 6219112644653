import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';
import { Label } from '../directives/label/label.model';
import { Line } from '../relation/line/line';
import { Point } from '../relation/relation.types';
import { RelationsService } from '../services/relations.service';
import { selectHighlightedSpans } from '../store/entity/entity.reducer';
import { selectVisibleRelations } from '../store/relation/relation.reducer';
import { State } from '../store/span/span.reducer';

@Component({
  selector: 'app-relation-labels',
  templateUrl: './relation-labels.component.html',
  styleUrls: ['./relation-labels.component.scss']
})
export class RelationLabelsComponent implements OnInit {
  @Input()
  mouse: Point;
  labels$: Observable<any[]>;
  highlightedSpans$: Observable<string[]>;
  highlightedRelations$: Observable<string[]>;


  constructor(private relationsService: RelationsService, private store: Store<State>) { }

  ngOnInit(): void {
    const relations$ = this.store.select(selectVisibleRelations);
    this.labels$ = this.relationsService.labels$;
    this.highlightedSpans$ = this.store.select(selectHighlightedSpans);
    this.highlightedRelations$ = this.highlightedSpans$.pipe(
      withLatestFrom(relations$),
      map(([highlightedSpans, relations]) => {
        return relations
          .filter(relation => {
            return this.intersectingArrays(highlightedSpans, relation.from)
              || this.intersectingArrays(highlightedSpans, relation.to);
          })
          .map(relation => {
            return relation.id;
          })
      })
    );
  }

  isHighlighted(line: Line, highlightedRelations: string[], highlightedSpans: string[]) {
    const relationId = line.id;
    const isAtStart = highlightedSpans.includes(line.startSpan.id);
    const isAtEnd = highlightedSpans.includes(line.endSpan.id);
    const isRelevant = highlightedRelations.includes(relationId)
      && (isAtStart || isAtEnd)
    if (!isRelevant) {
      return false;
    }
    if (highlightedRelations.length === 1 || !this.mouse) {
      return true;
    }
    if (isAtStart) {
      const distance = Math.abs(this.mouse.x - line.start.x)
      return distance < 4;
    }
    const distance = Math.abs(this.mouse.x - line.end.x);
    return distance < 4;
  }

  trackById(label:Label){
    return label.id;
  }

  private intersectingArrays<T>(a: T[], b: T[]): boolean {
    if (a.length === 0 && b.length === 0) {
      return false;
    }
    return b.some(lastIntersection => a.indexOf(lastIntersection) !== -1);
  }

}
