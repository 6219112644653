import { HttpClientModule } from '@angular/common/http';
import { InjectionToken, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { Action, ActionReducerMap, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { DialogModule, HeaderModule, SnackbarModule } from 'elements-lib';
import { UserStoreModule } from 'pages-lib';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppStateModule } from './shared/app/app-state/app-state.module';
import { ConfigProviderService } from './shared/app/app-state/config-provider.service';
import { RouterEffectsEffects } from './shared/effects/router-effects.effects';
import { UserEffects } from './shared/effects/user.effects';
import { PromptsModule } from './shared/prompts/prompts.module';
import { ReportsModule } from './shared/reports/reports.module';
import * as fromVis from './visualizer/store';
import { VisualizerModule } from './visualizer/visualizer.module';

const ROOT_REDUCERS = new InjectionToken<ActionReducerMap<fromVis.State, Action>>("Root reducers token",
  {
    factory: () => fromVis.reducers
  });

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    VisualizerModule,
    HttpClientModule,
    StoreModule.forRoot(ROOT_REDUCERS, {}),
    EffectsModule.forRoot([]),
    StoreDevtoolsModule.instrument({ maxAge: 250, logOnly: environment.production }),
    HeaderModule,
    UserStoreModule,
    ReportsModule,
    AppStateModule,
    StoreRouterConnectingModule.forRoot(),
    EffectsModule.forFeature([RouterEffectsEffects, UserEffects]),
    SnackbarModule,
    DialogModule,
    PromptsModule
  ],
  providers: [
    {
      provide: 'env',
      useValue: environment
    },
    {
      provide: 'config',
      useClass: ConfigProviderService
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
