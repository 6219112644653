export const environment = {
  production: true,
  homePath: '/text-entry',
  configPath: 'assets/config.json',
  server: 'http://numinus:2265',
  emtelliproServer: 'http://numinus:2265',
  iamServer: 'http://numinus:12265',
  commitId: 'e9619699',
  timeStamp: '2024.05.03'
};
