import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, filter, find, map, mergeAll, switchMap, take } from 'rxjs/operators';
import { State } from '../../store';
import { Concept } from '../../store/concept/concept.model';
import * as ConceptReducers from '../../store/concept/concept.reducer';
import { Entity } from '../../store/entity/entity.model';
import { selectDisabledTypes, selectEntityById, selectHighlightedSpans } from '../../store/entity/entity.reducer';
import { getSelectedOntology } from '../../store/ontologies/ontologies.reducer';
import { Span } from '../../store/span/span.model';

@Component({
  selector: 'app-entity-meta',
  templateUrl: './entity-meta.component.html',
  styleUrls: ['./entity-meta.component.scss']
})
export class EntityMetaComponent implements OnInit {
  @Input('span')
  span: Span;
  highlighted$: Observable<boolean>;
  entity$: Observable<Entity>;
  entityType$: Observable<string>;
  concepts$: Observable<Concept[]>;
  selectedConcept$: Observable<Concept>;

  readonly yOffset: number;

  constructor(private store: Store<State>) {
    this.yOffset = 0;
  }

  ngOnInit(): void {
    const selectedOntology$ = this.store.select(getSelectedOntology);
    this.highlighted$ = this.store.pipe(
      select(selectHighlightedSpans),
      distinctUntilChanged((a, b) => {
        if (a.length !== b.length) {
          return false;
        }
        for (const aItem of a) {
          if (!b.includes(aItem)) {
            return false;
          }
        }
        return true;
      }),
      map(highlightedSpans => highlightedSpans.includes(this.span.id))
    );
    this.entity$ = this.store.select(selectEntityById, this.span.id);
    this.entityType$ = this.selectEntityType(selectedOntology$);
    this.concepts$ = this.selectConcepts();
    this.selectedConcept$ = this.filterConcepts(selectedOntology$);
  }

  private selectEntityType(selectedOntology$: Observable<string>) {
    return combineLatest([
      selectedOntology$,
      this.entity$,
      this.store.select(selectDisabledTypes)
    ]).pipe(
      filter(([selectedOntology, entity, disabledTypes]) => !!entity),
      map(([selectedOntology, entity, disabledTypes]) => {
        const entityType = entity.entity_type[selectedOntology];
        return !disabledTypes.includes(entityType) && entityType;
      })
    );
  }

  private selectConcepts() {
    return this.entity$
      .pipe(
        filter(entity => !!entity),
        switchMap(entity => {
          return this.store.select(ConceptReducers.selectConceptsByIds, entity.concept_links);
        })
      );
  }

  private filterConcepts(selectedOntology$: Observable<string>) {
    return combineLatest([
      selectedOntology$,
      this.concepts$
    ]).pipe(
      map(([selectedOntology, concepts]) => {
        return concepts.find(concept => {
          return concept.ontology === selectedOntology;
        });
      })
    );
  }

}
