<div class="ontology-nav frame">
    <lib-top-nav-bar *ngIf="(ontologySelectorDisplay$ | async) === DisplayState.VISIBLE" [buttons]="ontologies$ | async"
        [default]="'snomed'" (onChange)="onOntologyChange($event)">
    </lib-top-nav-bar>
    <app-selectors-top></app-selectors-top>
</div>
<div class="vis-frame">
    <div class="sections vis-root" #visRoot>
        <app-section *ngFor="let section of sections$ | async" [section]="section" class="section"></app-section>
        <app-relation [mouse]="mouse"></app-relation>
        <app-meta></app-meta>
        <app-relation-labels [mouse]="mouse"></app-relation-labels>
    </div>
</div>
